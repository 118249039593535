import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/firestore'

import store from "./store";

var firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
};

if (!firebase.apps.length) {
  const firebaseApp = firebase.initializeApp(firebaseConfig)
  
  if(window.location.hostname === 'localhost') {
    // To access callable functions that are not in the emulator but deployed in the cloud (useful for cloud functions created by extensions).
    Vue.prototype.$deployedFunctions = firebaseApp.functions('europe-west3');
    // To access functions of the emulator
    firebaseApp.functions().useFunctionsEmulator('http://localhost:5001');
    Vue.prototype.$functions = firebaseApp.functions();
  } else {
    Vue.prototype.$deployedFunctions = firebaseApp.functions('europe-west3');
    Vue.prototype.$functions = firebaseApp.functions('europe-west3');
  }

  Vue.prototype.$firebase = firebaseApp
  Vue.prototype.$db = firebaseApp.firestore()
  Vue.prototype.$auth = firebaseApp.auth()
  Vue.prototype.$storage = firebaseApp.storage();

  let unsubscribeFirebaseUserListener, unsubscribeFirebaseSubscriptionsListener;

  firebase.auth().onAuthStateChanged(async authUser => {
    if (authUser) {
      // User is signed in
      store.dispatch('setIsUserLoggedIn', true)

      // Subscribe to its user document
      unsubscribeFirebaseUserListener = Vue.prototype.$db.collection('users').doc(authUser.uid).onSnapshot(userSnap => {
        const user = userSnap.data();
        if (user) {
          store.dispatch('setUserData', user)
        }
      })
      
      // Subscribe to its subscription document
      unsubscribeFirebaseSubscriptionsListener = Vue.prototype.$db.collection('users').doc(authUser.uid).collection('subscriptions').where('status', 'in', ['trialing', 'active', 'past_due', 'unpaid']).onSnapshot(async (snapshot) => {
        // In this implementation we only expect one 'trialing', 'active', 'past_due' or 'unpaid' subscription to exist.
        if (snapshot.docs.length === 0) {
          store.dispatch('setSubscription', null)    
        } else {
          const doc = snapshot.docs[0];
          const subscription = doc.data()
          subscription.id = doc.id
          const simplifiedSubscription = {
            status: subscription.status,
            current_period_start: subscription.current_period_start,
            current_period_end: subscription.current_period_end,
            items: subscription.items,
            cancel_at_period_end: subscription.cancel_at_period_end
          }
          store.dispatch('setSubscription', simplifiedSubscription)
        }
      });
    
    } else {
      // User is not signed in
      store.dispatch('setIsUserLoggedIn', false)

      if (unsubscribeFirebaseUserListener) unsubscribeFirebaseUserListener();
      store.dispatch('setUserData', null)

      if (unsubscribeFirebaseSubscriptionsListener) unsubscribeFirebaseSubscriptionsListener();
      store.dispatch('setSubscription', null)

      // User got logged out. If user is in a page where unlogged persons are not allowed, redirect him outside.
      let routerOption = router.options.routes.find(option => option.path === router.currentRoute.path)
      if (routerOption && !!routerOption.beforeEnter && ['checkIsLoggedIn'].includes(routerOption.beforeEnter.name)) {
        // User is still at a page where it is required to be loggedIn.
        router.push('/')
      }
    }
  });
}

Vue.config.productionTip = false
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
