<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        Hello
      </v-flex>
      <v-flex xs12>
        {{ stuff }}
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios'

const functionsUrlBase = {
  'localhost': 'http://localhost:5001/matchinghr-dev/us-central1',
  'matchinghr-dev.firebaseapp.com': 'https://europe-west3-matchinghr-dev.cloudfunctions.net',
  'matchinghr-prod.firebaseapp.com': 'https://europe-west3-matchinghr-prod.cloudfunctions.net',
  'matching.hr': 'https://europe-west3-matchinghr-prod.cloudfunctions.net'
}[window.location.hostname]

export default {
  components: {

  },
  data: () => ({
    stuff: null
  }),
  async mounted() {
    this.stuff = (await axios.get(`${functionsUrlBase}/helloWorld`)).data
  }
}
</script>

<style>
  .textAsLink {
    text-decoration: underline !important;
    cursor: pointer !important
  }
</style>